import * as React from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip";

const ProjectImage = ({projects, projIndex, row, col, max}) => {
    if (projects[projIndex] && (projIndex < max)) {
        const data = projects[projIndex].node.childMarkdownRemark.frontmatter;
        let itemClass = "col-lg-3 col-md-3 col-sm-6 px-2";
        if (col === 2) {itemClass += " pmm"};
        if (col === 3) {itemClass += " psm"};
        return (
        <>
            <div className={itemClass} data-tip data-for={(projIndex+1).toString()} >
                {
                    data.extLink?
                    <a target="_blank" rel="noreferrer" href={data.extLink}>
                        <img src={"/" + data.featuredImage} alt={data.title}
                             className="img-fluid img-responsive img aos-init aos-animate" data-aos="fade-in"
                             data-aos-duration={(projIndex + 15) * 100}/>
                    </a>
                        :
                    <img src={"/" + data.featuredImage} alt={data.title}
                         className="img-fluid img-responsive img aos-init aos-animate" data-aos="fade-in"
                         data-aos-duration={(projIndex + 15) * 100}/>
                }
            </div>
            <ReactTooltip id={(projIndex+1).toString()} place="top" effect="solid" backgroundColor="white" textColor="black" border={true}>
                {data.title.toUpperCase()}
            </ReactTooltip>
        </>
        )
    } else {
        return (<div />);
    }
}

const ProjectImages = ({ projects, max }) => {
    let projCount = projects.length;
    projCount = max?Math.min(max, projCount):projCount;
    const nrOfRows = Math.ceil(projCount / 4);
    let projectImagesJSX=[];
    for (let r=1; r<=nrOfRows; r++) {
        const currIndex  = (r-1) * 4;
        projectImagesJSX.push(
            <div key = {r} className={"row"}>
                <ProjectImage max={max} projects={projects} projIndex={currIndex} row={r} col={1}/>
                <ProjectImage max={max} projects={projects} projIndex={currIndex + 1} row={r} col={2}/>
                <ProjectImage max={max} projects={projects} projIndex={currIndex + 2} row={r} col={3}/>
                <ProjectImage max={max} projects={projects} projIndex={currIndex + 3} row={r} col={4}/>
            </div>
        );
    }
    return (
        <div className="container-fluid gallery">
            {projectImagesJSX}
        </div>
    )
}

ProjectImages.propTypes = {
    projects: PropTypes.array.isRequired,
}

export default ProjectImages
