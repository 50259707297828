import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout';
import ProjectImages from '../components/project-images';
import ProjectContents from '../components/project-contents';
//import SEO from "../components/seo"

export const query = graphql`
  query {
    projects: allFile (filter: {
            sourceInstanceName: {eq: "content"}, 
            relativeDirectory: {eq: "projects"}
         }
        sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC} 
      ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              date
              subtitle1
              subtitle2
              subtitle3
              featuredImage
              extLink
              content
            }
          }
          name
        }
      }
    }
    pageMetaData: allFile (
    filter: {
      sourceInstanceName: {eq: "content"}, relativeDirectory: {eq: "pages"},
      name:{eq: "projectsPage"}
    }) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              maxPics
            }
          }
        }
      }
    }
  }`

const TitlesPage = (props) => {
    return (
        <Layout pageName = "projectsPage">
            <ProjectImages projects={props.data.projects.edges} max={props.data.pageMetaData.edges[0].node.childMarkdownRemark.frontmatter.maxPics}/>
            <ProjectContents projects={props.data.projects.edges} />
        </Layout>
    )
};

export default TitlesPage
