import * as React from "react";
import PropTypes from "prop-types";

const showdown = require('showdown');
const converter = new showdown.Converter();


const MarkDown = ({md, className}) => {
    return (
        <div className={className?className:""} dangerouslySetInnerHTML={{ __html: converter.makeHtml(md) }} />
    );
};

const ProjectContents = ({ projects }) => {
    let projectContentsJSX=[];
    projects.forEach((proj)=> {
        const slug = proj.node.name;
        const data = proj.node.childMarkdownRemark.frontmatter;
        projectContentsJSX.push(
            <React.Fragment key={slug}>
                <div className="row margintb fixedColRow">
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 fixedColRowItemLeft">
                        <div className="left-index titles-left titles-left1 py-0">
                            <h2>
                                {data.extLink?
                                    <a target="_blank" rel="noreferrer" href={data.extLink}>{data.title}</a>
                                    :
                                    data.title
                                }
                            </h2>
                            <h3><MarkDown md={data.subtitle1} className={"project-subtitle1"}/></h3>
                        </div>
                    </div>
                    <div className="col-xxl-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 fixedColRowItemRight">
                        <div className="right-index titles-right titles-right1 py-0">
                            <MarkDown md={data.subtitle2} className={"project-subtitle2"}/>
                            <h3 className="my-4"><MarkDown md={data.subtitle3} className={"project-subtitle3"}/></h3>
                            <MarkDown md={data.content} className={"project-content"}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    });

    return (
        <div className="row">
            {projectContentsJSX}
        </div>
    )
}

ProjectContents.propTypes = {
    projects: PropTypes.array.isRequired,
}

export default ProjectContents
